import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { PortableText } from '@portabletext/react';
import SectionComponent from '../components/pageSections/PageSectionComponent';
import CTALink from '../components/CTA/CTALink';
import CTASecondary from '../components/CTA/CTASecondary';
import PageWrap from '../components/PageLayout/PageWrap';
import Serializers from '../serializers/Serializers';
import getLocalizedData from '../utils/getLocalizedData';
import getStringTranslations from '../../gatsby-node-utils/getStringTranslations';

const colorShape = PropTypes.shape({
  color: PropTypes.shape({
    hex: PropTypes.string,
  }),
});

const propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    indexPath: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    faqSubTopic: PropTypes.shape({
      introduction: PropTypes.arrayOf(PropTypes.any),
      body: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
      blocks: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
      callsToAction: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
      category: PropTypes.objectOf(PropTypes.any),
      pageMetadata: PropTypes.objectOf(PropTypes.any),
      title: PropTypes.string.isRequired,
    }).isRequired,
    theme: PropTypes.shape({
      pageMainTextColor: colorShape,
      pageMainBgColor: colorShape,
      pageMainAccentColor: colorShape,
    }),
  }).isRequired,
};

export const query = graphql`
  query FAQSubTopic($id: String!, $parent: String!) {
    theme: sanityFaqTopic(id: { eq: $parent }) {
      pageMainTextColor {
        color {
          hex
        }
      }
      pageMainBgColor {
        color {
          hex
        }
      }
      pageMainAccentColor {
        color {
          hex
        }
      }
    }
    faqSubTopic: sanityFaqSubTopic(id: { eq: $id }) {
      category {
        title {
          en
          no
        }
      }
      title {
        en
        no
      }
      introduction: _rawIntroduction(resolveReferences: { maxDepth: 5 })
      body: _rawBody(resolveReferences: { maxDepth: 5 })
      blocks: _rawBlocks(resolveReferences: { maxDepth: 6 })
      callsToAction: _rawCallsToAction(resolveReferences: { maxDepth: 5 })
      SubNavigation: _rawSubNavigation(resolveReferences: { maxDepth: 10 })
      subNavigation {
        _updatedAt
      }
      pageMetadata {
        titleTag {
          no
          en
        }
        metaDescription {
          no
          en
        }
      }
    }
  }
`;

function FaqSubTopic({ pageContext, data: { faqSubTopic, theme } }) {
  const { locale, indexPath } = pageContext;
  const localizedSubFaqTopic = getLocalizedData(faqSubTopic, locale);
  const { SubNavigation } = faqSubTopic;
  const localizedSubNavigation = getLocalizedData(SubNavigation, locale);
  const {
    introduction,
    body,
    blocks,
    callsToAction,
    category,
    pageMetadata,
    title,
  } = localizedSubFaqTopic;

  const pageBuilder = blocks ?? [];

  const stringTranslations = getStringTranslations(locale);

  const hasCTA = callsToAction?.length > 0;

  return (
    <PageWrap
      theme={theme}
      seoData={pageMetadata}
      subNavigation={localizedSubNavigation}
    >
      <section className="py-5 mb-5 section bg-off-white">
        <div className="flex flex-col items-center section-wrap px-7 xl:px-0 xl:flex-row xl:items-start">
          <div className="flex flex-col w-full mt-16 mb-7 xl:block section-column xl:w-68 xl:mr-15 xl:shrink-0 xl:mt-5">
            <CTALink as="button" margin="0" arrowRotation={180} to={indexPath}>
              {`${stringTranslations.backTo} ${category?.title}`}
            </CTALink>
          </div>

          <div className="prose section-content prose-ul:list-none">
            <header className="prose xl:mb-15 prose-p:text-lg prose-headings:my-3 prose-a:text-inherit prose-ul:list-none">
              <h1>{title}</h1>

              {introduction && (
                <PortableText value={introduction} components={Serializers} />
              )}
            </header>
          </div>
        </div>
      </section>

      {(body || hasCTA) && (
        <section className="section">
          <div className="flex flex-col-reverse items-center section-wrap px-7 xl:px-0 xl:flex-row xl:items-start">
            {hasCTA && (
              <div className="flex flex-col w-full section-column xl:block xl:w-68 xl:shrink-0 xl:mr-15 xl:mt-0 mt-15">
                {callsToAction?.map((item) => (
                  <div className="mt-7">
                    <CTASecondary data={item} />
                  </div>
                ))}
              </div>
            )}

            {body && (
              <div className="mb-8 prose section-content prose-ul:list-none">
                <PortableText value={body} components={Serializers} />
              </div>
            )}
          </div>
        </section>
      )}

      {pageBuilder.map((section) => (
        <SectionComponent
          key={section._key}
          componentType={section._type}
          locale={locale}
          {...section}
        />
      ))}
    </PageWrap>
  );
}

FaqSubTopic.propTypes = propTypes;

export default FaqSubTopic;
